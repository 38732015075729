import { Typography, AppBar, Toolbar, Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext } from "react";
import AuthContext from "../auth";

export default function Header() {
    const { auth } = useContext(AuthContext);
    return (
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
                backgroundColor: "#E0F0FF",
            }}
        >
            <Toolbar variant="dense">
                <Typography color="#2D394A" variant="h5" style={{ flexGrow: 1, fontWeight: 900 }}>
                    Stony Brook University Hospital
                </Typography>
                <IconButton
                    size='small'
                    sx={{
                        backgroundColor: "white",
                        "&:hover": { backgroundColor: "lightgray" },
                    }}
                    onClick={auth.logoutUser}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: ".5%",
                        borderRadius: "5 px",
                        marginLeft: "1%",
                    }}
                >
                    <Typography fontSize="12px" variant="subtitle1" style={{ marginRight: "7vw" }}>
                        {auth.user.name}
                    </Typography>
                    <Typography
                        variant="caption"
                        style={{ color: "gray", fontStyle: "italic" }}
                    >
                        nurse
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar >
    );
}
