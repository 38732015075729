
import { createContext } from "react";

const StaticFitBitContext = createContext({
    staticHeartRate: [],
    staticSpo2: [],
});

export default StaticFitBitContext;

