import { createContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
import apis from "./store-request-api";
// import AuthContext from "../auth";

/*
    This is our global data store. Note that it uses the Flux design pattern,
    which makes use of things like actions and reducers.
*/

// THIS IS THE CONTEXT WE'LL USE TO SHARE OUR STORE
export const GlobalStoreContext = createContext({});
console.log("create GlobalStoreContext");

// THESE ARE ALL THE TYPES OF UPDATES TO OUR GLOBAL
// DATA STORE STATE THAT CAN BE PROCESSED
export const GlobalStoreActionType = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  CREATE_PATIENT: "CREATE_PATIENT",
  LOAD_PATIENTS: "LOAD_PATIENTS",
  CREATE_NURSE: "CREATE_NURSE",
  LOAD_NURSES: "LOAD_NURSES",
  CREATE_DEVICE: "CREATE_DEVICE",
  LOAD_DEVICES: "LOAD_DEVICES",
  UPDATE_DEVICE: "UPDATE_DEVICE",
  MARK_PATIENTS_FOR_NURSE: "MARK_PATIENTS_FOR_NURSE",
  DELETE_NURSE: "DELETE_NURSE",
  DELETE_PATIENT: "DELETE_PATIENT",
  DELETE_DEVICE: "DELETE_DEVICE",
  UPDATE_PATIENT: "UPDATE_PATIENT",
};

export const UserType = {
  ADMIN: "ADMIN",
  NURSE: "NURSE",
};

// WE'LL NEED THIS TO PROCESS TRANSACTIONS
// const tps = new jsTPS();

// WITH THIS WE'RE MAKING OUR GLOBAL DATA STORE
// AVAILABLE TO THE REST OF THE APPLICATION
function GlobalStoreContextProvider(props) {
  // THESE ARE ALL THE THINGS OUR DATA STORE WILL MANAGE
  const [store, setStore] = useState({
    login: false,
    patients: null,
    nurses: null,
    devices: null,
    currentUser: null,
    currentHospital: null,
  });

  //  const navigate = useNavigate();

  // console.log("inside useGlobalStore");

  // SINCE WE'VE WRAPPED THE STORE IN THE AUTH CONTEXT WE CAN ACCESS THE USER HERE
  //   const { auth } = useContext(AuthContext);
  //   console.log("auth: " + auth.getUserDetails());

  // HERE'S THE DATA STORE'S REDUCER, IT MUST
  // HANDLE EVERY TYPE OF STATE CHANGE
  const storeReducer = (action) => {
    const { type, payload } = action;
    switch (type) {
      case GlobalStoreActionType.CREATE_PATIENT: {
        return setStore({
          patients: payload,
          nurses: store.nurses,
          devices: store.devices,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.CREATE_NURSE: {
        return setStore({
          patients: store.patients,
          nurses: payload,
          devices: store.devices,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.LOAD_PATIENTS: {
        return setStore((currentStore) => ({
          patients: payload,
          nurses: currentStore.nurses,
          devices: currentStore.devices,
          currentUser: currentStore.currentUser,
          currentHospital: currentStore.currentHospital,
        }));
      }
      case GlobalStoreActionType.DELETE_PATIENT: {
        return setStore({
          patients: payload,
          nurses: store.nurses,
          devices: store.devices,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.CREATE_DEVICE: {
        return setStore({
          patients: store.patients,
          nurses: store.nurses,
          devices: payload,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.LOAD_DEVICES: {
        return setStore((currentStore) => ({
          patients: currentStore.patients,
          nurses: currentStore.nurses,
          devices: payload,
          currentUser: currentStore.currentUser,
          currentHospital: currentStore.currentHospital,
        }));
      }
      // STOP EDITING THE CURRENT LIST
      case GlobalStoreActionType.DELETE_DEVICE: {
        return setStore({
          patients: payload,
          nurses: store.nurses,
          devices: store.devices,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.UPDATE_DEVICE: {
        return setStore({
          patients: store.patients,
          nurses: store.nurses,
          devices: payload,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      // CREATE A NEW LIST
      case GlobalStoreActionType.LOAD_NURSES: {
        return setStore((currentStore) => ({
          patients: currentStore.patients,
          nurses: payload,
          devices: currentStore.devices,
          currentUser: currentStore.currentUser,
          currentHospital: currentStore.currentHospital,
        }));
      }
      // GET ALL THE LISTS SO WE CAN PRESENT THEM
      case GlobalStoreActionType.MARK_PATIENTS_FOR_NURSE: {
        return setStore({
          patients: store.patients,
          nurses: payload,
          devices: store.devices,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.UPDATE_PATIENT: {
        return setStore({
          patients: payload,
          nurses: store.nurses,
          devices: store.devices,
          currentUser: store.currentUser,
          currentHospital: store.currentHospital,
        });
      }
      case GlobalStoreActionType.LOGIN_SUCCESS: {
        return setStore({
          ...store,
          isLoggedIn: true,
          authToken: payload.token,
          errorMessage: null,
        });
      }
      case GlobalStoreActionType.LOGIN_FAILURE: {
        return setStore({
          ...store,
          isLoggedIn: false,
          authToken: null,
          errorMessage: payload.errorMessage,
        });
      }
      case GlobalStoreActionType.LOGOUT: {
        return setStore({
          ...store,
          isLoggedIn: false,
          authToken: null,
        });
      }
      default:
        return store;
    }
  };

  //BREAKING CHANGE
  //Patient Schema seems to have been updated without updating backend route
  //Backend team should update the route to use new schema (no note section ? no username ?)

  store.createPatient = async function (
    name,
    nickname,
    dob,
    comments,
    FitBitID
  ) {
    const data = { dob, comments };
    const res = await apis.createPatient(name, nickname, data, FitBitID);
    if (res.status === 201) {
      const newPatient = res.data;
      const newPatients = [...store.patients, newPatient];
      storeReducer({
        type: GlobalStoreActionType.CREATE_PATIENT,
        payload: newPatients,
      });
    }
  };
  store.createNurse = async function (name, username, password) {
    const res = await apis.createNurse(name, username, password);
    if (res.status === 201) {
      const newNurse = res.data;
      const newNurses = [...store.nurses, newNurse];
      storeReducer({
        type: GlobalStoreActionType.CREATE_NURSE,
        payload: newNurses
      })
    }
  };
  // Retrieve patients from database and loads them into store
  store.loadPatients = async function () {
    const res = await apis.getPatients();
    const patients = res.data;
    storeReducer({
      type: GlobalStoreActionType.LOAD_PATIENTS,
      payload: patients,
    });
    return patients;
  };

  store.loadDevices = async function () {
    const res = await apis.getDevices();
    const devices = res.data;
    storeReducer({
      type: GlobalStoreActionType.LOAD_DEVICES,
      payload: devices,
    });
  };

  store.createDevice = async function (FitBitID, nickname) {
    const res = await apis.createDevice(FitBitID, nickname);
    if (res.status === 201) {
      const newDevice = res.data;
      const newDevices = [...store.devices, newDevice];
      storeReducer({
        type: GlobalStoreActionType.CREATE_DEVICE,
        payload: newDevices,
      });
    }
  };

  store.loadNurses = async () => {
    try {
      const res = await apis.loadNurses();
      if (res.status === 200) {
        const nurses = res.data;
        storeReducer({
          type: GlobalStoreActionType.LOAD_NURSES,
          payload: nurses,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  store.markPatientsForNurse = async (patients, nurse) => {
    try {
      const res = await apis.markPatientsForNurse(patients, nurse);
      if (res.status === 200) {
        const updated = res.data;
        const updatedNurses = [...store.nurses, updated];
        storeReducer({
          type: GlobalStoreActionType.MARK_PATIENTS_FOR_NURSE,
          payload: updatedNurses,
        });
      }
    } catch (err) {
      console.lof(err);
    }
  };

  store.deleteNurse = async (nurse) => {
    try {
      const res = await apis.deleteNurse(nurse);
      if (res.status === 200) {
        const updatedNurses = store.nurses.filter((n) => n._id !== nurse._id);
        storeReducer({
          type: GlobalStoreActionType.DELETE_NURSE,
          payload: updatedNurses,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  store.deletePatient = async (patient) => {
    try {
      const res = await apis.deletePatient(patient);
      if (res.status === 200) {
        const updatedPatients = store.patients.filter(
          (p) => p._id !== patient._id
        );
        storeReducer({
          type: GlobalStoreActionType.DELETE_PATIENT,
          payload: updatedPatients,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  store.deleteDevice = async (device) => {
    try {
      const res = await apis.deleteDevice(device);
      if (res.status === 200) {
        const updatedDevices = store.devices.filter(
          (d) => d._id !== device._id
        );
        storeReducer({
          type: GlobalStoreActionType.DELETE_DEVICE,
          payload: updatedDevices,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  store.updatePatient = async (patient, name, nickname, dob, comments, deviceFitBitID) => {
    try {
      const device = store.devices.find((device) => device.FitBitID === deviceFitBitID)._id; // Actually the device_id, but have to name it device for the backend route to work
      const res = await apis.updatePatient(patient, name, nickname, dob, comments, device);
      const device_id = device;
      const newDevice = store.devices.find((device) => device._id === device_id);
      if (res.status === 200) {
        patient.name = name;
        patient.nickname = nickname;
        patient.dob = dob;
        patient.comments = comments;
        patient.device.patient = null;
        patient.device = newDevice;
        patient.device.patient = patient;
        newDevice.patient = patient;

        const updatedDevices = store.devices;
        const updatedPatients = store.patients;
        storeReducer({
          type: GlobalStoreActionType.UPDATE_PATIENT,
          payload: updatedPatients,
        });
        storeReducer({
          type: GlobalStoreActionType.UPDATE_DEVICE,
          payload: updatedDevices,
        })
      }
    } catch (err) {
      console.log(err);
    }

  }

  //   // Retrieve nurses from database and loads them into store
  //   store.loadNurses = async function () {
  //     const nurses = await api.getNurses();
  //     storeReducer({
  //       type: GlobalStoreActionType.LOAD_NURSES,
  //       payload: nurses,
  //     });
  //   };

  return (
    <GlobalStoreContext.Provider
      value={{
        store,
      }}
    >
      {props.children}
    </GlobalStoreContext.Provider>
  );
}

export { GlobalStoreContextProvider };
