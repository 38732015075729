import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, CssBaseline, Box, Typography, Link } from '@mui/material';
import AuthContext from '../auth';

// This component will render different content based on the account type
const SignUpScreen = ({ accountType }) => {
  const [formData, setFormData] = useState({
    // hospitalName: '',
    name: '',
    userName: '',
    password: '',
    confirmPassword: '',
  });

  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  // Update formData state on input change
  const handleChange = (e) => {
    const { name, value } = e.target; // Get name and value from event target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the specific field
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();


    const data = new FormData(e.currentTarget);
    await auth.registerUser({
      name: data.get('name'),
      userName: data.get('userName'),
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
    });

    console.log("Registration successful. Logging in...");
    await auth.loginUser(data.get('name'), data.get('password'));
    navigate("/");

    // Reset form fields (optional)
    setFormData({
      // hospitalName: '',
      name: '',
      userName: '',
      password: '',
      confirmPassword: '',
    });


  };

  // Determine the title and whether to show the hospital name field based on account type
  const title = accountType === 'admin' ? 'Sign Up As Admin' : 'Sign Up As Nurse';
  const showHospitalField = accountType === 'admin';

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box m={1.5}>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1150px-React-icon.svg.png"
            className="Logo"
            alt="logo"
            sx={{ m: 2 }}
            width={115}
            height={100}
          />
        </Box>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoFocus
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
        <Typography variant="body2">
          Already have an account?{' '}
          <Link
            to="/login"
            onClick={() => navigate('/login')}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Log in
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUpScreen;
