// /*
//     This is our http api for all things auth, which we use to
//     send authorization requests to our back-end API. Note we`re
//     using the Axios library for doing this, which is an easy to
//     use AJAX-based library. We could (and maybe should) use Fetch,
//     which is a native (to browsers) standard, but Axios is easier
//     to use when sending JSON back and forth and it`s a Promise-
//     based API which helps a lot with asynchronous communication.
//     @author Aneesh Surasani
// */

import axios from 'axios'
axios.defaults.withCredentials = true;
const api = axios.create({
    baseURL: 'https://cicaida.cs.stonybrook.edu/auth',
})

// THESE ARE ALL THE REQUESTS WE`LL BE MAKING, ALL REQUESTS HAVE A
// REQUEST METHOD (like get) AND PATH (like /register). SOME ALSO
// REQUIRE AN id SO THAT THE SERVER KNOWS ON WHICH LIST TO DO ITS
// WORK, AND SOME REQUIRE DATA, WHICH WE WE WILL FORMAT HERE, FOR WHEN
// WE NEED TO PUT THINGS INTO THE DATABASE OR IF WE HAVE SOME
// CUSTOM FILTERS FOR QUERIES

//export const getLoggedIn = () => api.get(`/loggedIn/`);
export const loginUser = (userName, password) => {
    return api.post(`/login/`, {
        userName: userName,
        password: password
    })
}
export const logoutUser = () => api.get(`/logout/`);
export const registerUser = (name, userName, password, confirmPassword) => {
    return api.post(`/register/`, {
        name: name,
        userName: userName,
        password: password,
        confirmPassword: confirmPassword
    })
}
const apis = {
    registerUser,
    loginUser,
    logoutUser
}

export default apis
