/*
    This is our http api, which we use to send requests to
    our back-end API. Note we`re using the Axios library
    for doing this, which is an easy to use AJAX-based
    library. We could (and maybe should) use Fetch, which
    is a native (to browsers) standard, but Axios is easier
    to use when sending JSON back and forth and it`s a Promise-
    based API which helps a lot with asynchronous communication.
    
    @author McKilla Gorilla
*/

import axios from "axios";
axios.defaults.withCredentials = true;
const api = axios.create({
  baseURL: "https://cicaida.cs.stonybrook.edu/api",
});
const admin = axios.create({
  baseURL: "https://cicaida.cs.stonybrook.edu/admin",
});

// THESE ARE ALL THE REQUESTS WE`LL BE MAKING, ALL REQUESTS HAVE A
// REQUEST METHOD (like get) AND PATH (like /top5list). SOME ALSO
// REQUIRE AN id SO THAT THE SERVER KNOWS ON WHICH LIST TO DO ITS
// WORK, AND SOME REQUIRE DATA, WHICH WE WE WILL FORMAT HERE, FOR WHEN
// WE NEED TO PUT THINGS INTO THE DATABASE OR IF WE HAVE SOME
// CUSTOM FILTERS FOR QUERIES

export const createPatient = (name, nickname, data, FitBitID) => {
  return admin.post("/createPatient", {
    // SPECIFY THE PAYLOAD
    name,
    nickname,
    data,
    FitBitID,
  });
};

export const createNurse = (name, username, password) => {
  return admin.post("/createNurse", {
    // SPECIFY THE PAYLOAD
    name,
    username,
    password,
  });
};

export const getDevices = () => admin.get("/devices");

export const getPatients = () => api.get("/patients");

export const createDevice = (FitBitID, nickname) => {
  return admin.post("/addAdevice", {
    FitBitID: FitBitID,
    nickname: nickname,
  });
};

export const loadNurses = () => admin.get("/nurses");

export const markPatientsForNurse = (patients, nurse) => {
  return api.post("/markPatients", {
    patients: patients,
    nurse: nurse,
  });
};

export const deleteNurse = (nurse) => {
  return api.delete(`/delete/${nurse._id}`);
};

export const deletePatient = (patient) => {
  return admin.delete("/deletePatient", {
    patiendID: patient._id,
  });
};

export const deleteDevice = (device) => {
  return admin.post("/deleteDevice", {
    deviceID: device._id,
  });
};

export const updatePatient = (patient, name, nickname, dob, comments, device) => {
  return admin.post(`/updatePatient/${patient._id}`, {
    name, nickname, dob, comments, device
  });
};

const apis = {
  createPatient,
  createNurse,
  getPatients,
  loadNurses,
  createDevice,
  deleteNurse,
  deletePatient,
  deleteDevice,
  updatePatient,
  getDevices,
};

export default apis;
