// @author Aneesh Surasani

import React, { createContext, useEffect, useState, useReducer } from "react";
import { useNavigate } from 'react-router-dom'
import api from './auth-request-api'

const AuthContext = createContext();


// THESE ARE ALL THE TYPES OF UPDATES TO OUR AUTH STATE THAT CAN BE PROCESSED
export const AuthActionType = {
    GET_LOGGED_IN: "GET_LOGGED_IN",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    REGISTER_USER: "REGISTER_USER",
    LOGIN_ERROR: "LOGIN_ERROR",
}

function AuthContextProvider(props) {
    const [auth, setAuth] = useState({
        user: null,
        loggedIn: false,
        errorMessage: null
    });
    const initialState = {
        user: null,
        loggedIn: false,
        errorMessage: null
    };

    const navigate = useNavigate();


    const authReducer = (state, action) => {
        const { type, payload } = action;
        switch (type) {
            case AuthActionType.GET_LOGGED_IN: {
                return setAuth({
                    user: payload.user,
                    loggedIn: payload.loggedIn,
                    errorMessage: null
                });
            }
            case AuthActionType.LOGIN_USER: {
                return setAuth({
                    user: payload.user,
                    loggedIn: payload.loggedIn,
                    errorMessage: payload.errorMessage
                })
            }
            case AuthActionType.LOGOUT_USER: {
                return setAuth({
                    user: null,
                    loggedIn: false,
                    errorMessage: null
                })
            }
            case AuthActionType.REGISTER_USER: {
                return setAuth({
                    user: payload.user,
                    loggedIn: payload.loggedIn,
                    errorMessage: payload.errorMessage
                })
            }
            case AuthActionType.LOGIN_ERROR: {
                return {
                    ...state,
                    errorMessage: action.payload.errorMessage,
                    loggedIn: false,
                    user: null
                };
            }
            default:
                return state;
        }
    }
    const [state, dispatch] = useReducer(authReducer, initialState);

    // auth.getLoggedIn = async function () {
    //     const response = await api.getLoggedIn();
    //     if (response.status === 200) {
    //         authReducer({
    //             type: AuthActionType.GET_LOGGED_IN,
    //             payload: {
    //                 loggedIn: response.data.loggedIn,
    //                 user: response.data.user
    //             }
    //         });
    //     }
    // }

    auth.registerUser = async function ({ name, userName, password, confirmPassword }) {
        console.log("REGISTERING USER");
        try {
            const response = await api.registerUser(name, userName, password, confirmPassword);
            if (response.status === 200) {

                // Use dispatch here
                dispatch({
                    type: AuthActionType.REGISTER_USER,
                    payload: {
                        user: response.data.user,
                        loggedIn: true, // Assuming you want to log in the user upon registration
                        errorMessage: null
                    }
                });
                navigate("/login");

                // Make sure loginUser is also using dispatch appropriately
                auth.loginUser(userName, password);
                navigate("/");
            }
        } catch (error) {
            // Use dispatch here as well
            dispatch({
                type: AuthActionType.REGISTER_USER,
                payload: {
                    user: null,
                    loggedIn: false,
                    errorMessage: error.response ? error.response.data.errorMessage : 'An error occurred'
                }
            });
        }
    };


    auth.loginUser = async function (userName, password) {
        try {

            const response = await api.loginUser(userName, password);

            if (response.status === 200) {
                dispatch({
                    type: AuthActionType.LOGIN_USER,
                    payload: {
                        user: response.data.user,
                        loggedIn: true,
                        errorMessage: null
                    }
                })
                navigate("/", { replace: true });
            }
        } catch (error) {
            console.log(error)
            dispatch({
                type: AuthActionType.LOGIN_ERROR, // You might need to add this action type
                payload: {
                    errorMessage: "Login failed: " + error.message // More descriptive
                }
            });
            throw error
        }
    }

    auth.logoutUser = async function () {
        try {
            const response = await api.logoutUser();
            if (response.status === 200) {
                // Successfully logged out on the server, now update the client state.
                dispatch({
                    type: AuthActionType.LOGOUT_USER
                });
                // Redirect to the login page or any other page
                navigate("/login");
            } else {
                // Handle cases where the server response is not successful
                dispatch({
                    type: AuthActionType.LOGIN_ERROR,
                    payload: {
                        errorMessage: "Failed to log out."
                    }
                });
            }
        } catch (error) {
            // Handle errors if the API call itself fails
            dispatch({
                type: AuthActionType.LOGIN_ERROR,
                payload: {
                    errorMessage: "Logout failed: " + error.message
                }
            });
        }
    }


    auth.getUserInitials = function () {
        let initials = "";
        if (auth.user) {
            initials += auth.user.firstName.charAt(0);
            initials += auth.user.lastName.charAt(0);
        }
        console.log("user initials: " + initials);
        return initials;
    }

    auth.getUserDetails = function () {

        if (auth.user) {
            return auth.user;
        }
    }

    return (
        <AuthContext.Provider value={{
            auth
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
export { AuthContextProvider };
