import { Card, IconButton, Divider, CardHeader, Box, Grid2 as Grid, Paper, styled, CardContent, Typography, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import FavoriteIcon from '@mui/icons-material/Favorite';

const dataset = [
    { x: 1, y: 70 },
    { x: 2, y: 70 },
    { x: 3, y: 80 },
    { x: 4, y: 70 },
    { x: 5, y: 75 },
    { x: 6, y: 65 },
    { x: 7, y: 70 },
    { x: 8, y: 75 },
    { x: 9, y: 70 },
    { x: 10, y: 75 },
    { x: 11, y: 70 },
    { x: 12, y: 75 },
    { x: 13, y: 70 },
    { x: 14, y: 75 },
    { x: 15, y: 70 },
    { x: 16, y: 75 },
    { x: 17, y: 70 },
    { x: 18, y: 85 },
    { x: 19, y: 70 },
    { x: 20, y: 75 },
    { x: 21, y: 70 },
];

function BPMRangeBarChart() {

}

function BPMLineChart() {
    const y_values = dataset.map((point) => point.y);
    const y_max = Math.max(...y_values);
    const y_min = Math.min(...y_values);
    return (
        <LineChart
            dataset={dataset}
            xAxis={[{ dataKey: 'x' }]}
            series={[{
                dataKey: 'y', curve: "linear", showMark: ({ index }) => y_values[index] === y_max || y_values[index] === y_min
            }]}
            bottomAxis={null}
            leftAxis={null}
            height={300}
            margin={{ left: 15, right: 15, top: 30, bottom: 30 }}
            grid={{
                vertical: true, horizontal: true,
            }}
            colors={["#39CADF"]}
            sx={{
                "& .MuiChartsGrid-line": {
                    stroke: "rgba(255, 255, 255, 0.75)"
                },
                "& .MuiChartsAxis-line ": {
                    stroke: "rgba(0, 0, 0, 0.75)",
                },
                "& .MuiMarkElement-root": {
                    stroke: "rgba(255, 0, 0, 1)",
                    fill: "rgba(255, 0, 0, 1)",
                },
                "& .MuiMarkElement-highlighted": {
                    stroke: "rgba(255, 255, 255, 1)",
                    fill: "rgba(255, 255, 255, 1)",
                },
            }}

        />
    );
}

function BPMBarChart() {
    const y_values = dataset.map((point) => point.y);
    const y_max = Math.max(...y_values);
    const y_min = Math.min(...y_values);
    const y_med = ((y_max - y_min) / 2) + y_min;
    let bar_chart_dataset = []
    for (let i = 0; i < y_values.length; i += 2) {
        const y = y_values[i];
        const pos = y / 2
        const neg = pos * -1
        const range = { high: pos, low: neg }
        bar_chart_dataset.push(range)
    }
    console.log(bar_chart_dataset)
    const chartSettingsH = {
        dataset: bar_chart_dataset,
        height: 300,
        yAxis: [{ dataKey: 'high', max: 100, min: -100 }, { dataKey: 'low' }],
        slotProps: {
            legend: { hidden: true }
        },
    };
    return (
        <BarChart
            height={300}
            grid={{ horizontal: true }}
            series={[
                { dataKey: 'high', layout: "vertical", stack: "stack" },
                { dataKey: 'low', layout: "vertical", stack: "stack" },
            ]}
            {...chartSettingsH}
            sx={{
                "& .MuiBarElement-root": {
                    // "clip-path": "inset(0px round 20px)",
                    fill: "#FF0000"
                },
                "& .MuiChartsGrid-line": {
                    stroke: "rgba(0, 0, 0, 1)"
                },
            }}
            margin={{
                top: 10,
                bottom: 20,
                left: 0,
            }}
            leftAxis={null}
            xAxis={[
                {
                    scaleType: 'band',
                    data: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    categoryGapRatio: 0.7,
                    barGapRatio: 0,
                }]}

            borderRadius={20}
            tooltip={{ trigger: 'none' }}
        />
    )
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    textAlign: 'center',
    fontWeight: "bold",
    color: "#48515D",
    boxShadow: "none"
}));

function BasicGrid({ id }) {
    const patient_id = id
    const y_values = dataset.map((point) => point.y);
    const y_max = Math.max(...y_values);
    const y_min = Math.min(...y_values);
    const y_med = ((y_max - y_min) / 2) + y_min
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid size={12} marginBottom={4} marginTop={4}>
                    <Typography color="#48515D" fontSize={"20px"} fontWeight={"900"} align="center">Patient Name: Candle Jenner</Typography>
                </Grid>
                <Grid size={4} >
                    <Stack direction="row" alignItems="center" gap={1} justifyContent={"center"}>
                        <FavoriteIcon sx={{ fill: "red", fontSize: "3rem" }} />
                        <Typography variant="h4">Heartrate</Typography>
                    </Stack>
                    <Typography align="center" variant="h3" color="#58e878" margin="30px">98 BPM</Typography>
                    <Card sx={{ margin: "50px", backgroundColor: "#78DEED" }}>
                        <CardContent align="center">
                            <Typography variant="h5" marginBottom="30px" fontWeight={"bold"}>
                                RANGE: 60-78 BPM
                            </Typography>
                            <Typography variant="h5" fontWeight={"bold"}>
                                HIGHEST: 78 BPM
                            </Typography>
                            <Typography variant="h5" fontWeight={"bold"}>
                                LOWEST: 60 BPM
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={8}>
                    <Typography align="center" sx={{ fontWeight: "100", fontSize: "1rem", marginBottom: "20px" }}>Heart Rate Range</Typography>
                    <BPMBarChart />
                </Grid>
                <Grid size={4} alignContent={"end"}>
                    <Card sx={{ marginX: "120px", backgroundColor: "#78DEED", "& .MuiCardContent-root": { paddingBottom: "16px" } }} >
                        <CardContent align="center" >
                            <Typography variant="h5" color="#5CA904" fontWeight={"bold"}>
                                ACTIVE
                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={7} backgroundColor="#000000">
                    <BPMLineChart />
                </Grid>
                <Grid container size={1} spacing={10}>
                    <Grid marginTop={3}>
                        <Item>{y_max} BPM</Item>
                    </Grid>
                    <Grid>
                        <Item>{y_med} BPM</Item>
                    </Grid>
                    <Grid>
                        <Item>{y_min} BPM</Item>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
}

function PatientCard({ id }) {
    return <Card>
        <CardHeader
            title="Detailed Patient View"
            titleTypographyProps={{
                fontWeight: "bold",
                color: "#48515D",
            }}
            action={
                <>
                    <IconButton
                        aria-label="search"
                        size="small"
                        sx={{
                            backgroundColor: "#67BCE4",
                            "&:hover": { backgroundColor: "lightskyblue" },
                            mr: '.5rem'
                        }}
                    >
                        <SearchIcon sx={{
                            color: "white"
                        }} />
                    </IconButton>
                </>
            }
        ></CardHeader>
        <Divider variant="fullWidth" sx={{ background: 'black', position: 'relative', margin: "5px" }}></Divider>
        <CardContent>
            <BasicGrid id={id} />
        </CardContent>
    </Card>
}

export default function NewSinglePageView() {
    const { id } = useParams();
    return (

        <Box backgroundColor="#E0F0FF" margin={0} padding={1}>
            <PatientCard id={id} />
        </Box>
    )
}