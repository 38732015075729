
import StaticFitBitContext from "./StaticFitBitContext";
import { useState, useEffect } from "react";

const StaticFitBitContextProvider = ({ children }) => {
    const [staticHeartRate, setStaticHeartRate] = useState([]);
    const [staticSpo2, setStaticSpo2] = useState([]);

    useEffect(() => {
        const loadStaticData = async () => {
            try {
                const heartRate = await fetch("./heart-rate.json", {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                });
                const heartRateData = await heartRate.json();
                const minValues = heartRateData["activities-heart"]
                    .map((entry) =>
                        entry.value.heartRateZones.map((zone) => zone.min)
                    )
                    .flat();
                setStaticHeartRate(minValues);
            } catch (error) {
                console.error(error);
                console.error("error fetching heart rate data");
            }

            try {
                const spo2 = await fetch("./sp02.json");
                const spo2Data = await spo2.json();
                const spo2Values = Object.values(spo2Data)
                    .map((entry) =>
                        Object.values(entry.minutes)
                            .map((minute) => minute.value)
                            .flat()
                    )
                    .flat();
                setStaticSpo2(spo2Values);
            } catch (error) {
                console.error("Error fetfching spo02 data");
                console.error(error);
            }
        };

        loadStaticData();
    }, []);

    const staticValues = {
        staticHeartRate,
        staticSpo2,
    };
    return (
        <StaticFitBitContext.Provider value={staticValues}>
            {children}
        </StaticFitBitContext.Provider>
    );
};

export default StaticFitBitContextProvider;