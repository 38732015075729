import * as React from "react";
import Drawer from "@mui/material/Drawer";
import SvgIcon from "@mui/material/SvgIcon";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";
import { ReactComponent as CICaidAIcon } from '../svgs/CICaidA_logo.svg';
import { useNavigate, useLocation } from "react-router-dom";



export default function SideMenu({ selected, setSelected }) {
    const drawerWidth = 170;
    const menus = ["Home", "Devices", "Nurses", "Patients"];
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    backgroundColor: "#50545c"
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <div align="center" style={{ height: "135px", padding: "10px" }} >
                <SvgIcon component={CICaidAIcon} inheritViewBox style={{ width: "100px", height: "115px" }} />
            </div>
            <List>
                {menus.map((text, index) => {
                    let route = "/" + text.toLowerCase()
                    return <>
                        <Divider variant="middle" sx={{ background: 'white' }}></Divider>
                        <ListItem key={text} disablePadding >
                            <ListItemButton
                                onClick={() => {
                                    navigate(route);
                                }}
                                sx={[
                                    {
                                        ":hover": {
                                            backgroundClip: "padding-box",
                                            boxShadow: "inset -10px 0 0px #40ccdc",
                                        },
                                        padding: "5px"
                                    },
                                    location.pathname === route && {
                                        backgroundClip: "padding-box",
                                        boxShadow: "inset -10px 0 0px #40ccdc",
                                    },
                                ]}
                            >
                                <ListItemText>
                                    <Typography color={"white"} align="center" >{text}</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </>
                })}
                <Divider variant="middle" sx={{ background: 'white' }}></Divider>
            </List>
        </Drawer>
    );
}
