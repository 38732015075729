import React, { useContext, useEffect, useRef } from "react";
import { GlobalStoreContext } from "../store";
import AuthContext from '../auth';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import NewDrawer from "../new_components/NewDrawer.js"
import Header from "../new_components/Header.js";
import { Divider } from "@mui/material";
import { Outlet, Navigate } from "react-router-dom";
const _ = require('lodash');


export default function MainPage() {
  const [selected, setSelected] = React.useState("Home");
  const { store } = useContext(GlobalStoreContext);
  const { auth } = useContext(AuthContext);

  if (!auth.user) {
    return <Navigate to='/login' />
  }

  // function deepCompareEquals(a, b) {
  //   return _.isEqual(a, b);
  // }

  // function useDeepCompareMemoize(value) {
  //   const ref = useRef();
  //   if (!deepCompareEquals(value, ref.current)) {
  //     if (value !== null) ref.current = value;
  //   }
  //   return ref.current;
  // }

  // function useDeepCompareEffect(callback, dependencies) {
  //   useEffect(callback, dependencies.map(useDeepCompareMemoize));
  // }

  // // Load patients on first render
  // useEffect(() => {
  //   store.loadPatients();
  // }, []);



  // // Reload when store is updated
  // useDeepCompareEffect(store.loadDevices, [store.nurses, store.patients]);
  // useDeepCompareEffect(store.loadPatients, [store.devices, store.nurses]);
  // useDeepCompareEffect(store.loadNurses, [store.patients, store.devices]);
  const isAdmin = auth.user && Array.isArray(auth.user.devices);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NewDrawer selected={selected} setSelected={setSelected} />
      <Box
        component="main"

        sx={{ flexGrow: 1, bgcolor: "#E0F0FF", p: 3, padding: 0 }}
      >
        <Header />
        <Divider variant="fullWidth" sx={{ background: 'black', position: 'relative', margin: "5px" }}></Divider>
        <Outlet />
      </Box>
    </Box>
  );
}