import React, { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../auth";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useNavigate, Redirect } from "react-router-dom";
import { getLoggedIn, loginUser } from "../auth/auth-request-api";

function Fail() {
  return (
    <Alert open={false} severity="error">
      Email/password is incorrect.
    </Alert>
  );
}

export default function LoginScreen() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);

  let errorAlert = loginError ? <Fail /> : "";


  const handleLogin = (event) => {
    event.preventDefault();
    setLoginError(false); // Reset login error state

    const data = new FormData(event.currentTarget);
    auth.loginUser(data.get("userName"), data.get("password"))
      .then(() => {
        auth.loggedIn = true;
        navigate("/home");
      })
      .catch((error) => {
        setLoginError(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Welcome!
        </Typography>
        <Box m={1.5}>
          <img
            src={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1150px-React-icon.svg.png"
            }
            className="Logo"
            alt="logo"
            sx={{ m: 2 }}
            width={115}
            height={100}
          />
        </Box>
        <Typography variant="body2" marginBottom={"1em"}>
          CICaida is a health triage app designed for easily tracking patient
          vitals via Bluetooth connection.
        </Typography>
        <Typography component="h1" variant="h5" marginBottom={"1em"}>
          Login
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          {errorAlert}
          <TextField
            margin="normal"
            required
            fullWidth
            id="userName"
            label="Username"
            name="userName"
            autoComplete="userName"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
        </Box>
        <Button
          type="button" // Change type to "button" to prevent form submission
          fullWidth
          variant="contained"
          onClick={() => navigate('/signup')} // Use navigate to navigate
        >
          Create Account
        </Button>
      </Box>
    </Container>
  );
}
