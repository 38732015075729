import "./css/App.css";
import { React, useContext } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import SignUpScreen from "./components/SignUpScreen";
import Root from "./components/Root";
import LoginScreen from "./components/LoginScreen"
import NewSinglePageView from "./new_components/NewSinglePageView";
import NewNurseHomePage from "./new_components/NewNurseHomePage";
import MainPage from "./components/MainPage";
import NewLoginPage from "./new_components/NewLoginScreen";

/*
  This is the entry-point for our application. Notice that we
  inject our store into all the components in our application.

  @author Aneesh Surasani
*/
// const App = () => {

//   return (
//     <BrowserRouter>
//       <AuthContextProvider>
//         <GlobalStoreContextProvider>
//           <StaticFitBitContextProvider>
//             <Routes>
//               <Route element={<PrivateRoutes />}>
//                 <Route path="/" element={<MainPage />} >
//                   <Route path='home' element={<NewNurseHomePage />} />
//                   <Route path='patient/:id' element={<NewSinglePageView />} />
//                 </Route>
//               </Route>
//               <Route path="/login" element={<LoginPage />} />
//               <Route path="/signup" element={<SignUpScreen />} />
//             </Routes>
//           </StaticFitBitContextProvider>
//         </GlobalStoreContextProvider>
//       </AuthContextProvider>
//     </BrowserRouter>
//   );
// };

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/login",
        element: <LoginScreen />,
      },
      {
        path: "/signup",
        element: <SignUpScreen />
      },
      {
        path: "/",
        element: <MainPage />,
        children: [
          {
            path: "/",
            element: <Navigate to="home" replace />,
          },
          {
            path: "home",
            element: <NewNurseHomePage />,
          },
          {
            path: "patient/:id",
            element: <NewSinglePageView />
          }
        ],
      },
    ]

  }
]);

const App = () => {
  return <RouterProvider router={router} />
}

// const PrivateRoutes = () => {
//   const { auth } = useContext(AuthContext); // Use useContext to access auth context
//   return (
//     auth.loggedIn ? <Outlet /> : <Navigate to='/login' />
//   )
// }

export default App;
