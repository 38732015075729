import React, { useContext, useEffect, useRef } from "react";
import { AuthContextProvider } from "../auth";
import { Outlet } from "react-router-dom";
import { GlobalStoreContextProvider } from "../store/index.js";
import StaticFitBitContextProvider from "./StaticFitBitContextProvider.js"



export default function Root() {
    return (
        <AuthContextProvider>
            <GlobalStoreContextProvider>
                <StaticFitBitContextProvider>
                    <Outlet />
                </StaticFitBitContextProvider>
            </GlobalStoreContextProvider>
        </AuthContextProvider>
    );
}