import { React, useContext } from 'react';
import { Grid, Card, CardHeader, CardContent, IconButton, Typography, Box, CardActionArea } from '@mui/material';
import Divider from '@mui/material/Divider';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom'


const myPatients = [
  { condition: 'Urgent', name: 'Candie Jenner', lastChecked: '4:00 PM', location: 'Bed 201B', o2Saturation: 89, abnormalities: '' },
  { condition: 'Cautionary', name: 'Jennifer Lupez', lastChecked: '4:00 PM', location: 'Bed 200B', o2Saturation: 93, abnormalities: '' },
  { condition: 'Normal', name: 'Gary Styles', lastChecked: '4:00 PM', location: 'Bed 203B', o2Saturation: 99, abnormalities: '' },
  { condition: 'Normal', name: 'Sarah Johnson', lastChecked: '4:00 PM', location: 'Bed 203B', o2Saturation: 99, abnormalities: '', id: "65cc0b870a8af02aff98ccaa" },
];

const allPatients = [
  { condition: 'Urgent', name: 'Candie Jenner', lastChecked: '4:00 PM', location: 'Bed 201B', o2Saturation: 89, abnormalities: '' },
  { condition: 'Urgent', name: 'Nicole Minaj', lastChecked: '5:00 PM', location: 'Bed 202B', o2Saturation: 88, abnormalities: '' },
  { condition: 'Urgent', name: 'Sophia Vergara', lastChecked: '5:34 PM', location: 'Bed 204B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Urgent', name: 'Justin Timber', lastChecked: '4:13 PM', location: 'Bed 206B', o2Saturation: 78, abnormalities: '' },
  { condition: 'Urgent', name: 'Reese Witherfork', lastChecked: '4:00 PM', location: 'Bed 206B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Justin Fever', lastChecked: '2:00 PM', location: 'Bed 207B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Jennifer Lupez', lastChecked: '4:00 PM', location: 'Bed 200B', o2Saturation: 93, abnormalities: '' },
  { condition: 'Cautionary', name: 'Taylor Slow', lastChecked: '4:00 PM', location: 'Bed 208B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Bee Onsay', lastChecked: '4:00 PM', location: 'Bed 209B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Tom Bruise', lastChecked: '4:00 PM', location: 'Bed 210B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Katy Terry', lastChecked: '4:00 PM', location: 'Bed 211B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Ryan Gosling', lastChecked: '4:00 PM', location: 'Bed 212B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Cautionary', name: 'Gary Styles', lastChecked: '4:00 PM', location: 'Bed 200B', o2Saturation: 99, abnormalities: '' },
  { condition: 'Cautionary', name: 'Tom Hollandaise', lastChecked: '4:00 PM', location: 'Bed 213B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Normal', name: 'Emma Rock', lastChecked: '4:00 PM', location: 'Bed 214B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Normal', name: 'Girl Gaga', lastChecked: '4:00 PM', location: 'Bed 215B', o2Saturation: 94, abnormalities: '' },
  { condition: 'Normal', name: 'Gary Styles', lastChecked: '4:00 PM', location: 'Bed 203B', o2Saturation: 99, abnormalities: '' },
];

const getBackgroundColor = (patient) => {
  const condition = patient.condition;
  const colorMap = {
    Urgent: "#FFACA7",
    Cautionary: "#FFD12D",
    Normal: "#86D385",
  };
  return colorMap[condition] || "white";
};

const PatientCard = ({ patient }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ minHeight: 210, backgroundColor: getBackgroundColor(patient), borderRadius: 4 }}>
      <CardActionArea onClick={() => {
        const id = patient.id;
        navigate(`/patient/${id}`)
      }}>
        <CardContent sx={{ minHeight: 210 }}>
          <Typography color="#48515D" fontWeight="600" fontSize="20px" align="center" variant="h6">{patient.name}</Typography>
          <Divider variant="fullWidth" sx={{ background: "#48515D", position: 'relative', margin: "5px" }}></Divider>
          <Typography color="#48515D" fontSize="14px" align="center">Last Checked: {patient.lastChecked}</Typography>
          <Typography color="#48515D" fontSize="14px" align="center">Location: {patient.location}</Typography>
          <Typography color="#48515D" aboutfontSize="14px" align="center">O2 Saturation: {patient.o2Saturation}%</Typography>
          <Typography color="#48515D" fontSize="14px" align="center">Abnormalities: {patient.abnormalities || 'None'}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
};

const PatientList = ({ title, patients, cardsPerRow }) => (
  <Card
    elevation={2} sx={{ padding: '1rem' }}>
    <CardHeader
      title={title}
      action={
        <>
          <IconButton
            aria-label="search"
            size="small"
            sx={{
              backgroundColor: "#67BCE4",
              "&:hover": { backgroundColor: "lightskyblue" },
              mr: '.5rem'
            }}
          >
            <SearchIcon sx={{
              color: "white"
            }} />
          </IconButton>
        </>
      }
      titleTypographyProps={{ variant: "h6", color: "#48515D" }}
      sx={{
        padding: '0',
        mb: '1.5rem',
        "& .MuiCardHeader-title": {
          fontWeight: "900",
        },
      }}></CardHeader>
    <Divider variant="fullWidth" sx={{ background: 'black', position: 'relative', top: '-.75rem' }}></Divider>
    <Grid container spacing={2}>
      {patients.map((patient, index) => (
        <Grid item xs={12} sm={6} md={12 / cardsPerRow} key={index}>
          <PatientCard patient={patient} />
        </Grid>
      ))}
    </Grid></Card>
);

const NewNurseHomePage = () => {
  return (
    <div style={{ backgroundColor: "#E0F0FF" }}>
      <Box display="flex" backgroundColor="#E0F0FF" margin={0} padding={1}>
        <Box flex={1} mr={2}>
          {<PatientList title="My Patients" patients={myPatients} cardsPerRow={2} />}
        </Box>
        <Box flex={2}>
          {<PatientList title="All Patients" patients={allPatients} cardsPerRow={4} />}
        </Box>
      </Box>
    </div >
  );
};

export default NewNurseHomePage;